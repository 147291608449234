import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

const useStyles = makeStyles(teamStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>What we do / 会社概要</h2>
          <h5>当会社は、次の事業を行うことを目的としています</h5>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.textLeft}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textLeft
          )}
        >
          <h5>
            <ol>
              <li>
                コンピュータのソフトウェア及びハードウェアの企画、研究、開発、設計、製造、販売、保守、リース、賃貸及び輸出入並びにそれらに関するコンサルティング業務{" "}
              </li>
              <li>
                前払式支払手段を活用した決済システムの研究、開発および販売
              </li>
              <li>
                IoT（Internet of
                Things）技術及びコンピュータ排熱を活用した農作物の生産およびその技術開発にかかわる業務
              </li>
              <li>
                竹林整備の調査・研究・コンサルティング及び竹製品の開発、販売に係る事業
              </li>
            </ol>
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
