import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

import FaceTomo from "assets/img/faces/tomo.jpg";
import FaceYoko from "assets/img/faces/yoko.jpg";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Our team</h2>
          <h5 className={classes.description}></h5>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem md={2} sm={2}>
          <Card profile plain>
            {" "}
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img src={FaceTomo} alt="profile-pic" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Tomofumi ONDA-OGA, Ph.D</h4>
              <h5 className={classes.cardTitle}>
                <small>博士（理学）</small> 恩田（小賀） 智文
              </h5>
              <h6 className={classes.textMuted}>CEO / Co-Founder</h6>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img src={FaceYoko} alt="profile-pic" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Yoko ONDA, PHN</h4>
              <h5 className={classes.cardTitle}>
                <small>看護師</small> 恩田 陽子
              </h5>
              <h6 className={classes.textMuted}>Finance / Co-Founder</h6>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={2} sm={2}>
          <Card profile plain>
            {" "}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
